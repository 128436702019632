import * as bootstrap from 'bootstrap'
import * as jquery from 'jquery'
import * as validate from 'jquery-validation'
// import * as ionicon from 'ionicons'

import "../css/fullpage.css";
import "../css/filled-bars.css";

// import * as jquery from './jquery-3.6.1.min.js'
// import "./jquery.validate.min.js";

// var fullpage = require('../js/fullpage.js');


//TODO: https://stackoverflow.com/questions/71698165/how-to-load-multiple-images-with-parcel
//https://parceljs.org/languages/javascript/#url-dependencies




if (module.hot) {
    module.hot.accept();
}

//window.addEventListener("DOMContentLoaded", function () {

//     if (document.querySelector('#fullpage')) {
//         var fullPageInstance = new fullpage('#fullpage', {
//             licenseKey: 'K1O4H-N022H-10MKK-1X8J9-TLNAQ',
//             // scrollBar: true
//             lockAnchors: false,
//             anchors: ['page1', 'page2', 'page3'],
//             navigation: true,
//             navigationPosition: 'right',
//             navigationTooltips: ['Home', 'Beverage can', 'Included services', 'We Trade', 'Impressions', 'Can samples', 'Team', 'Contact'],
//             showActiveTooltip: false,
//             autoScrolling: true,

//             touchSensitivity: 15,
//             keyboardScrolling: true,
//             fixedElements: '#navbar , #copy-bottom',
//             css3: true,
//             scrollingSpeed: 500,
//             // scrollBar: true,
//         });
//     };

//     if (document.querySelector('#fullpageDetails')) {
//         var fullPageInstance = new fullpage('#fullpageDetails', {
//             licenseKey: 'K1O4H-N022H-10MKK-1X8J9-TLNAQ',
//             // scrollBar: true
//             lockAnchors: false,
//             anchors: ['page1', 'page2', 'page3'],
//             navigation: true,
//             navigationPosition: 'right',
//             navigationTooltips: ['Intro', 'Product requirements', 'Label requirements', 'Site requirements', 'Setup', 'Exerpt'],
//             showActiveTooltip: false,
//             autoScrolling: true,
//             touchSensitivity: 15,
//             keyboardScrolling: true,
//             fixedElements: '#navbar , #copy-bottom',
//             css3: true,
//             scrollingSpeed: 500,
//             // scrollBar: true,
//         });
//     };
// })



// var fullPageInstance = new fullpage('#fullpage', {
//     licenseKey: 'K1O4H-N022H-10MKK-1X8J9-TLNAQ',
//     // scrollBar: true
//     lockAnchors: false,
//     anchors: ['page1', 'page2', 'page3'],
//     navigation: true,
//     navigationPosition: 'right',
//     navigationTooltips: ['Home', 'Beverage can', 'Included services', 'We Trade', 'Impressions', 'Can samples', 'Team', 'Contact'],
//     showActiveTooltip: false,
//     autoScrolling: true,
//     touchSensitivity: 15,
//     keyboardScrolling: true,
//     fixedElements: '#navbar , #copy-bottom',
//     css3: true,
//     scrollingSpeed: 500,
//     // scrollBar: true,
// });
// var fullPageInstance = new fullpage('#fullpageDetails', {
//     licenseKey: 'K1O4H-N022H-10MKK-1X8J9-TLNAQ',
//     // scrollBar: true
//     lockAnchors: false,
//     anchors: ['page1', 'page2', 'page3'],
//     navigation: true,
//     navigationPosition: 'right',
//     navigationTooltips: ['Intro', 'Product requirements', 'Included services', 'We Trade', 'Impressions', 'Can samples', 'Team', 'Contact'],
//     showActiveTooltip: false,
//     autoScrolling: true,
//     touchSensitivity: 15,
//     keyboardScrolling: true,
//     fixedElements: '#navbar , #copy-bottom',
//     css3: true,
//     scrollingSpeed: 500,
//     // scrollBar: true,
// });

(function ($) {
    "use strict";


    // var height = window.innerHeight;
    // var a = setInterval(function () {
    //     $(window).scrollTop(-1);
    //     resize();
    // }, 500); // Don't lower more than 500ms, otherwise there will be animation-problems with the  Safari toolbar

    // $(window).on('resize', function () {
    //     resize();
    // });

    // var resize = function () {
    //     if (window.innerHeight != height) {
    //         height = window.innerHeight;
    //         $('.section').css('height', height + 'px');
    //     }
    // };


    $(".navbar-toggle").on("click", function () {
        $("body")
            .removeClass("menu-is-closed")
            .addClass("menu-is-opened");
    });

    $(".close-menu, .click-capture, .menu-list li a").on("click", function () {
        $("body")
            .removeClass("menu-is-opened")
            .addClass("menu-is-closed");
        $(".menu-list ul").slideUp(300);
    });

    $(".menu-list li a").on("click", function () {
        $(".menu-list li").removeClass("active");
        $(this)
            .closest("li")
            .addClass("active");
    });

    $(".col-resume").on("mouseover", function () {
        $(".section-bg.mask").addClass("hide");
    });

    $(".col-resume").on("mouseleave", function () {
        $(".section-bg.mask").removeClass("hide");
    });

    /*-------------------------------------------------------------------------------
        Ajax Forms
      -------------------------------------------------------------------------------*/

    if ($(".js-form").length) {
        $(".js-form").each(function () {
            $(this).validate({
                rules: {
                    name: {
                        required: true,

                    },
                    mail: {
                        required: true,
                        email: true
                    },
                    email2: {
                        maxlength: 0
                    },
                    text: {
                        required: false,

                    },
                    message: {
                        required: true,

                    }
                },
                errorClass: "error",
                submitHandler: function (form) {
                    $.ajax({
                        type: "POST",
                        url: "mail.php",
                        data: $(form).serialize(),
                        success: function () {
                            $(".form-group-message").show();
                            $("#error").hide();
                            $("#sendBtn").hide();
                            $("#success").show();
                        },

                        error: function () {
                            $(".form-group-message").show();
                            $("#success").hide();
                            $("#error").show();
                        }
                    });
                }
            });
        });
    }
})(jquery);